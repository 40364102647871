import _ from "lodash"
import md5 from "md5"
import { Order } from "../entities/Order/Order"
import { OrderEvent } from "../entities/Order/OrderEvent"
import { CategoryItem, Modifier, ModifierItem } from "src/entities/Category/Category"
import { SubOrder } from "src/entities/Order/SubOrder/SubOrder"

//rename this to HashModifiersItems
// export const HashModifiers = (modifiersItems: ModifierItem[]): string => {
//   let hashString = ''
//   if (modifiersItems.length === 0) return ''

//   _.sortBy(modifiersItems, ['id', 'type', 'modifierId']).forEach((modItem) => {
//     if (modItem.event !== OrderEvent.Remove) hashString += `${modItem.modifierId}${modItem.type}${modItem.id}`
//   })

//   return md5(hashString)
// }

//rename this to HashModifiers
// export const HashModifiersV2 = (modifiers: Modifier[]): string => {
//   let hashString = ''

//   const sortedModifiers = _.sortBy(modifiers, ['id'])
//   sortedModifiers.forEach((modifier) => {
//     hashString += modifier.id
//     const items = _.sortBy(modifier.items, ['id', 'type'])
//     items.forEach((modItem) => {
//       if (modItem.event !== OrderEvent.Remove) hashString += `${modItem.id}${modItem.type}`
//     })
//   })

//   return md5(hashString)
// }

//rename this to HashModifiers
// export const temp = (modifiers: Modifier[]): string => {
//   let hashString = ''

//   const sortedModifiers = _.sortBy(modifiers, ['id'])
//   sortedModifiers.forEach((modifier) => {
//     if (modifier.event !== OrderEvent.Remove) {
//       hashString += modifier.id
//       const items = _.sortBy(modifier.items, ['id', 'type'])
//       items.forEach((modItem) => {
//         if (modItem.event !== OrderEvent.Remove) hashString += `${modItem.id}${modItem.type}`
//       })
//     }
//   })

//   return hashString
// }


// export const HashOrderItem = (item: GroupedItem): string => {
//   let hashString = `${item.itemId}${item.soldByWeight}`

//   if (!item.modifiers) return md5(hashString)

//   if (item.modifiers.length > 0) {
//     _.sortBy(item.modifiers, ['id']).forEach((modifier) => {
//       if (modifier.event !== OrderEvent.Remove) {
//         hashString += modifier.id
//         _.sortBy(modifier.items, ['id', 'type']).forEach((modItem) => {
//           if (modItem.event !== OrderEvent.Remove) hashString += `${modItem.id}${modItem.type}`
//         })
//       }
//     })
//   }

//   return md5(hashString)
// }

export const HashAllOrderItem = (item: CategoryItem) => {
  let hashString = `${item.itemId}${item.soldByWeight}`
  if (item.modifiers.length === 0) return md5(hashString)

  _.sortBy(item.modifiers, ['id']).forEach((modifier: Modifier) => {
    hashString += modifier.id
    _.sortBy(modifier.modifierItems, ['id']).forEach((modItem: ModifierItem) => {
      hashString += modItem.id
    })
  })

  return md5(hashString)
}

// export const HashOrder = (order: Order) => {
//   let hashString = order.info.references.id
//   _.sortBy(order.subOrders, ['createdDate']).forEach((subOrder: SubOrder) => {
//     hashString += subOrder.id
//     _.sortBy(subOrder.items, ['sortPriority']).forEach((item: CategoryItem) => {
//       hashString += `${item.itemId}${item.itemTypeId}`
//       _.sortBy(item.modifiers, ['sortPriority']).forEach((modifier: Modifier) => {
//         hashString += modifier.id
//         _.sortBy(modifier.items, ['sortPriority']).forEach((modItem: ModifierItem) => {
//           hashString += `${modItem.id}${modItem.type}`
//         })
//       })
//     })
//   })

//   // return md5(hashString)
//   return hashString
// }


export const HashOrder = (order: Order) => {
  let hashString = order.info.references.id
  order.subOrders.forEach((subOrder: SubOrder) => {
    hashString += subOrder.id
    subOrder.items.forEach((item: CategoryItem) => {
      hashString += `${item.itemId}${item.itemTypeId}`
      item.modifiers.forEach((modifier: Modifier) => {
        hashString += modifier.id
        modifier.modifierItems.forEach((modItem: ModifierItem) => {
          hashString += `${modItem.id}${modItem.type}`
        })
      })
    })
  })

  let sum = 0
  for (let i = 0; i < hashString.length; i++) {
    sum += hashString.charCodeAt(i)
  }

  return sum
}


export const ItemPriceByCategory = (item: CategoryItem): number => {
  if (!item) return 0
  let total = item.price

  if (item.modifiers) item.modifiers.forEach((modifier) => {
    const filteredItems = _.filter(modifier.modifierItems, function (item) {
      return item.event !== OrderEvent.Remove
    })
    total += _.sumBy(filteredItems, 'price')
  })

  return total
}

export const getDateDiff = (fromDate: Date, toDate: Date) => {

  const convertedFromDate = new Date(fromDate)
  const convertedToDate = new Date(toDate)
  if (convertedFromDate.getDay() !== convertedToDate.getDay()) return convertedFromDate.toLocaleDateString()

  const dateDiff = convertedFromDate.getTime() - convertedToDate.getTime()
  const min = Math.round(dateDiff / 60000)

  if (min > 90) return `${Math.round((min / 60))} hours ago`
  else return `${Math.round(min)} min ago`
}

export const parseCreationDate = (creationDate: Date) => {
  const convertedDate = new Date(creationDate)
  if (convertedDate.getDay() !== new Date().getDay()) return convertedDate.toLocaleDateString()
  return convertedDate.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' })
}

export const formatOrderNo = (orderNo: number | null) => {
  const length = process.env.REACT_APP_ORDER_NO_LENGTH ? parseInt(process.env.REACT_APP_ORDER_NO_LENGTH) : 0
  if (orderNo) return String(orderNo).padStart(length, '0')
  else return "-"
}

export const nextModifierItemSortPriority = (orderItem: CategoryItem | undefined, modifiers: Modifier[]): number => {
  let sortPriority = 0

  if (orderItem) orderItem.modifiers.forEach((modifier) => {
    const maxValue: ModifierItem | undefined = _.maxBy(modifier.modifierItems, 'sortPriority')
    if (maxValue) sortPriority = sortPriority > maxValue.sortPriority ? sortPriority : maxValue.sortPriority
  })

  modifiers.forEach((modifier) => {
    const maxValue: ModifierItem | undefined = _.maxBy(modifier.modifierItems, 'sortPriority')
    if (maxValue) sortPriority = sortPriority > maxValue.sortPriority ? sortPriority : maxValue.sortPriority
  })

  return sortPriority + 1
}

export const nextModifierSortPriority = (orderItem: CategoryItem | undefined, modifiers: Modifier[]): number => {
  let sortPriority = 0

  if (orderItem) orderItem.modifiers.forEach((modifier) => {
    const maxValue: Modifier | undefined = _.maxBy<number>(modifier, 'sortPriority')
    if (maxValue) sortPriority = sortPriority > maxValue.sortPriority ? sortPriority : maxValue.sortPriority
  })

  const maxValue: Modifier | undefined = _.maxBy<number>(modifiers, 'sortPriority')
  if (maxValue) sortPriority = sortPriority > maxValue.sortPriority ? sortPriority : maxValue.sortPriority

  return sortPriority + 1
}