import Customer from "src/entities/Customer/Customer"
import { getCustomers, getCustomersFromIndexedDb, updateCustomers } from "src/indexeddb/customer"

const customersReducer = async (state: Customer[] = [], action) => {

    switch (action.type) {
        case 'INIT_CUSTOMERS':
            const fetchedCustomers = await getCustomers()
            return fetchedCustomers
        case "SET_CUSTOMER":
            {
                const customers: Customer[] = await state
                const customer: Customer = action.payload

                const customersData = [...customers.filter(x => x.id !== customer.id), customer]

                await updateCustomers(customersData)
                return customersData
            }

        default:
            return state
    }
}

export default customersReducer