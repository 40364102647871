import dbConfig from "./db"
import axiosInstance from '../AxiosInstance'
import localforage from 'localforage'
import _ from "lodash"
import { v4 as guid } from 'uuid'
import { HashOrder } from "../utility/Helper"
import { Order } from "../entities/Order/Order"
import { SubOrder } from "../entities/Order/SubOrder/SubOrder"
import { OrderEvent } from "../entities/Order/OrderEvent"
import { Item } from "../entities/Order/SubOrder/Item"
import { Info } from "../entities/Order/Info/Info"
import { Channel } from "../entities/Channel"
import { References } from "../entities/Order/Info/References"
import { DeliveryAddress } from "../entities/Order/Info/Status/Delivery/DeliveryAddress"
import { OrderGrouped } from "../entities/Order/OrderGrouped"
import { OrderTypes } from "../entities/Order/OrderTypes"
import { OrderTypeInfo } from "src/entities/Order/Info/OrderTypeInfo"
import { CookingInfo } from "src/entities/Order/Info/CookingInfo"
import { DeliveryAreaInfo } from "src/entities/Order/Info/Status/Delivery/DeliveryAreaInfo"
import { DeliveryInfo } from "src/entities/Order/Info/Status/Delivery/DeliveryInfo"
import { Status } from "src/entities/Order/Info/Status/Status"
import { CategoryItem } from "src/entities/Category/Category"

const tableName = 'orders'

export async function pendingSync(): Promise<number> {
    const orders = await localforage.getItem<Order[]>(tableName)

    // return _.filter(orders, function (order) {
    //     return order.info.event !== OrderEvent.Processed
    // }).length
    return orders ? orders.filter(x => x.info.isProcessed === false).length : 0
}

export function newOrder(): Order {

    const orderTypeInfo: OrderTypeInfo = {
        id: OrderTypes.dineIn,
        name: ""
    }

    const cookingInfo: CookingInfo = {
        chefId: null,
        hasCookingItems: false
    }

    const deliveryAreaInfo: DeliveryAreaInfo = {
        areaName: null,
        cost: null,
        id: null,
        standardServiceTime: null
    }

    const deliveryInfo: DeliveryInfo = {
        courierId: null,
        deliveryStatus: null,
        deliveryStatusId: null,
        deliveryTime: null,
        deliveryAreaInfo
    }

    const status: Status = {
        isPaid: false,
        paymentTypeId: 0,
        deliveryInfo,
        cookingInfo
    }

    const references: References = {
        id: guid(),
        orderNo: null
    }

    const info: Info = {
        creationTime: new Date(),
        employeeId: 0,
        channel: process.env.REACT_APP_POS_CHANNEL ? process.env.REACT_APP_POS_CHANNEL : 'system',
        tableId: null,
        references,
        orderTypeInfo,
        customerId: null,
        address: null,
        status,
        isProcessed: false
    }

    const order: Order = {
        info,
        subOrders: []
    }

    return order
}

export function newSubOrder(): SubOrder {

    const subOrder: SubOrder = {
        orderNo: null,
        isPrinted: false,
        isForKitchen: false,
        isCooked: false,
        isLocked: false,
        lockedBy: 0,
        comment: "",
        createdDate: new Date(),
        items: [],
        id: guid()
    }

    return subOrder
}

export function newOrderItem(): CategoryItem {
    const item: CategoryItem = {
        itemId: guid(),
        itemName: "",
        price: 0,
        soldByWeight: false,
        needCooking: false,
        modifiers: [],
        barcode: null,
        imagePath: null,
        itemTypeId: "",
        cookingTimeMin: null,
        cookingTimeSec: null,
        unit: null,
        taxId: 0,
        taxName: null,
        taxType: null,
        taxPercent: 0,
        hash: "",
        sortPriority: 0,
        color: '#00000',
        quantity: null,
        categoryId: ""
    }

    return item
}
export async function getOrdersFromIndexedDb(): Promise<Order[]> {
    //localforage.config(dbConfig)
    const data = await localforage.getItem<Order[]>(tableName)

    return data ? data : []
}

// export async function updateOrderLocally(order: Order) {
//     const orders = await getOrdersFromIndexedDb()
//     if (!orders) return
//     const index = orders.findIndex(obj => obj.info.references.externalReference === order.info.references.externalReference)

//     orders[index] = order
//     orders[index].info.event = OrderEvent.Modified
//     await localforage.setItem(tableName, orders)
// }

// export function getActiveSubOrderItems(orderData: Order): CategoryItem[] | null {

//     const activeSubOrder = _.filter(orderData.subOrders, function (subOrder) {
//         return subOrder.isPrinted === false
//     })

//     if (activeSubOrder.length > 0) return activeSubOrder[0].items
//     else return []
// }

export const getActiveSubOrder = (orderData: Order): SubOrder => {
    const activeSubOrder = orderData.subOrders.find(subOrder => subOrder.isPrinted === false)

    if (activeSubOrder !== undefined) return activeSubOrder
    else {
        const subOrder: SubOrder = {
            orderNo: 0,
            isPrinted: false,
            isForKitchen: false,
            isCooked: false,
            isLocked: false,
            lockedBy: 0,
            comment: null,
            createdDate: new Date(),
            items: [],
            id: guid()
        }

        orderData.subOrders.push(subOrder)
        return orderData.subOrders[0]
    }
}

// export const getItemFromActiveSubOrder = (item: Item, activeSubOrder: SubOrder): Item => {
//     const itemsFound = _.filter(activeSubOrder.items, function (singleItem) {
//         return singleItem.transactionId === item.transactionId
//     })
//     return itemsFound[0]
// }

export async function setPaymentOption(orderData: Order, paymentId: number): Promise<Order> {
    // _.filter(orderData.subOrders, function(subOrder) {
    //     if (!subOrder.isPrinted) {
    //         subOrder.paymentTypeId = paymentId
    //         subOrder.event = OrderEvent.Modified
    //     }
    //  })

    // orderData.info.status.paymentTypeId = paymentId

    // await updateOrderLocally(orderData)
    return orderData
}

export const getItemTotalAmount = (item: Item): number => {
    let amount = 0

    if (!item) return 0

    // if (item.event === OrderEvent.Remove) return 0

    // if (item.modifiers) item.modifiers.forEach((modifier) => {
    //     if (modifier.items) {
    //         modifier.items.forEach((modifierItem) => {
    //             if (modifierItem.event !== OrderEvent.Remove) amount += modifierItem.price
    //         })
    //     }
    // })

    amount += item.price

    return amount
}

export const getPrice = (categoryItem: CategoryItem): number => {
    let price = 0
    categoryItem.modifiers.forEach((modifier) => {
        modifier.modifierItems.forEach((item) => {
            price += item.price * (item.quantity ? item.quantity : 0)
        })
    })

    price += categoryItem.price
    return price
}

export const activeSubOrderTotalAmount = (orderData: Order): number => {
    const subOrder = getActiveSubOrder(orderData)
    if (!subOrder) return 0

    let amount = 0
    subOrder.items.forEach(item => {
        amount += getPrice(item) * (item.quantity ? item.quantity : 0)
    })

    return amount
}

// export const subOrderTotalAmount = (orderData: SubOrder) : number => {
//     const subOrder = getActiveSubOrder(orderData)
//     if (!subOrder) return 0

//     let amount = 0
//     subOrder.items.forEach(item => {
//         if (item.event !== OrderEvent.Remove) {
//             amount += item.price
//             item.modifiers.forEach(modifier => {
//                 if (modifier.event !== OrderEvent.Remove) {
//                     modifier.items.forEach(mItem => {
//                         if (mItem.event !== OrderEvent.Remove) amount += mItem.price
//                     })
//                 }
//             })
//         }
//     })

//     return amount
// }

export const orderTotalAmount = (order: Order): number => {
    let total = 0
    for (const subOrder of order.subOrders) {
        const subTotal = _.sumBy(subOrder.items, function (item: Item) {
            return getItemTotalAmount(item)
        })

        total += subTotal
    }

    return total
}

// export async function getOrderExternalReference(externalReference: string): Promise<Order | undefined> {
//     //localforage.config(dbConfig)
//     const orders = await localforage.getItem<Order[]>(tableName)

//     const order = orders?.find(x => x.info.references.id === externalReference)
//     return order
// }

export const getOrders = async (): Promise<Order[]> => {

    const url = `/api/order/all?days=7`
    const response = await axiosInstance.get(url)

    if (response.status === 200) {
        //localforage.config(dbConfig)
        localforage.setItem(tableName, response.data)

        return response.data
    }

    return []
}

export const getGroupedByDateOrders = async (employeeId: number): Promise<OrderGrouped[]> => {
    //localforage.config(dbConfig)
    let ordersFromBackend: Order[] = []
    let result: OrderGrouped[] = []

    try {
        const response = await axiosInstance.get<Order[]>(`/api/order?employeeId=${employeeId}&days=2`)
        if (response) ordersFromBackend = response.data
    } catch (error) { ordersFromBackend = [] }

    const ordersFromIndexedDb = await localforage.getItem<Order[]>(tableName)

    if (ordersFromIndexedDb) {
        //marrim listen e orders qe jane ne backend por jo ne IndexedDb
        const filteredList: Order[] = ordersFromBackend.filter(obj1 => !ordersFromIndexedDb.some(obj2 => obj2.info.references.id === obj1.info.references.id))

        //i shtojme ne listen totale
        ordersFromBackend = [...ordersFromBackend, ...filteredList]

        //grupojme rezultatin sipas dates se krijimit
        result = _.chain(ordersFromIndexedDb)
            .groupBy(x => new Date(x.info.creationTime).toLocaleDateString())
            .map((value, key) => ({ days: key, orders: value }))
            .value()

        result.reverse()
    }

    return result
}

// export const getOrdersList = async (): Promise<Order[] | null> => {

//     //localforage.config(dbConfig)

//     const url = `/api/order`
//     const ordersFromBackend = await axiosInstance.get<Order[]>(url)
//     const ordersFromIndexedDb = await localforage.getItem<Order[]>(tableName)


//     ordersFromBackend.data.forEach(order => {
//         const orderFound = _.filter(ordersFromIndexedDb, function (singleOrder) {
//             return singleOrder.info.references.externalReference === order.info.references.id
//         })

//         if (!orderFound && ordersFromIndexedDb) ordersFromIndexedDb.push(orderFound)
//     })

//     return ordersFromIndexedDb

//     // if (ordersFromBackend.status === 200)  return ordersFromBackend.data
//     // else return []

// }

export const processOrdersFromIndexedDb = async () => {
    let orders = await getOrdersFromIndexedDb()
    let unProcessedOrders: Order[] = []
    let hasError = false

    unProcessedOrders = [...orders.filter(x => x.info.isProcessed === false)]

    //shiko mundesine per ti derguar ne batch veprimet
    for (let i = 0; i < unProcessedOrders.length; i++) {       
        try {
            const response = await axiosInstance.post(`/api/order`, unProcessedOrders[i])
            console.log(HashOrder(unProcessedOrders[i]), '|', HashOrder(response.data))
            console.log(JSON.stringify([unProcessedOrders[i], response.data]))
            if (response.status === 200) {
                if (HashOrder(unProcessedOrders[i]) === HashOrder(response.data)) {
                    unProcessedOrders[i] = response.data
                    orders = [...orders.filter(x => x.info.references.id !== unProcessedOrders[i].info.references.id), unProcessedOrders[i]]
                }
            } else {
                hasError = true
                console.log(response)
            }
        } catch (error) {
            hasError = true
            console.log(error)
        }
    }

    //localforage.config(dbConfig)
    await localforage.setItem(tableName, orders)

    return hasError
}

// export const applyCustomer = async (externalReference: string, customerId: string, orderType: string, userId: number) => {
//     //localforage.config(dbConfig)
//     let orders = await localforage.getItem<Order[]>(tableName)
//     let index = -1

//     const order = _.filter(orders, function (o, i) {
//         if (o.info.references.externalReference === externalReference) index = i
//         return o.info.references.externalReference === externalReference
//     })

//     if (orders && order.length > 0) {
//         orders[index].info.customerId = customerId

//     } else {
//         // const newOrder = JSON.parse(JSON.stringify(Order))

//         // newOrder.info.references.externalReference = externalReference
//         // newOrder.info.orderTypeInfo.id = parseInt(orderType)
//         // newOrder.info.channel = process.env.REACT_APP_POS_CHANNEL
//         // newOrder.info.event = OrderEvent.Raw
//         // newOrder.info.customerInfo.customerId = customerId
//         // newOrder.info.employeeId = userId
//         // newOrder.info.orderTypeInfo.name = GetOrderTypeDescription(orderType)

//         // if (orders) orders.push(newOrder)
//         //   else  orders = [newOrder]

//         const references: References = {
//             id: externalReference,
//             orderNo: 0
//         }

//         const orderTypeInfo: OrderTypeInfo = {
//             id: 0,
//             name: "!!!"//GetOrderTypeDescription(orderType)
//         }

//         const deliveryAreaInfo: DeliveryAreaInfo = {
//             areaName: "",
//             cost: 0,
//             id: 0,
//             standardServiceTime: 0
//         }

//         const deliveryInfo: DeliveryInfo = {
//             courierId: 0,
//             deliveryStatus: "",
//             deliveryStatusId: 0,
//             deliveryTime: null,
//             deliveryAreaInfo
//         }

//         const cookingInfo: CookingInfo = {
//             chefId: 0,
//             hasCookingItems: false
//         }

//         const status: Status = {
//             isPaid: false,
//             paymentTypeId: 0,
//             deliveryInfo,
//             cookingInfo
//         }

//         const deliveryAddress: DeliveryAddress = {
//             additionalInfo: "",
//             comment: "",
//             createdDate: new Date(),
//             customerId,
//             id: '',
//             street: "",
//             event: OrderEvent.Raw,
//             areaId: 0,
//             areaName: "",
//             standardServiceTime: 0
//         }

//         const orderInfo: Info = {
//             creationTime: new Date(),
//             employeeId: userId,

//             channel: Channel.pos,
//             tableId: 0,
//             references,
//             orderTypeInfo,
//             customerId: null,
//             address: null,
//             status
//         }

//         const newOrder: Order = {
//             info: orderInfo,
//             subOrders: []
//         }

//         if (orders) orders.push(newOrder)
//         else orders = [newOrder]
//     }

//     await localforage.setItem(tableName, orders)
// }

export const updateOrder = async (modifiedOrder: Order) => {
    //localforage.config(dbConfig)

    modifiedOrder.info.isProcessed = false
    const orders = await localforage.getItem<Order[]>(tableName)
    if (orders) {
        const allOrders: Order[] = [...orders.filter(x => x.info.references.id !== modifiedOrder.info.references.id), modifiedOrder]
        await localforage.setItem(tableName, allOrders)
    } else await localforage.setItem(tableName, [modifiedOrder])
}

// export const addItem = async (order: Order, orderItem: CategoryItem): Promise<Order> => {
//     //localforage.config(dbConfig)
//     const orders = await localforage.getItem<Order[]>(tableName)


//     const activeSubOrder = getActiveSubOrder(order)
//     if (activeSubOrder === null) {
//         const newSubOrder: SubOrder = {
//             orderNo: 0,

//             isPrinted: false,
//             isForKitchen: false,
//             isCooked: false,
//             isLocked: false,
//             lockedBy: 0,
//             comment: "",

//             createdDate: new Date(),
//             items: [orderItem],
//             id: ""
//         }
//         order.subOrders.push(newSubOrder)

//     } else {

//         if (activeSubOrder.items) activeSubOrder.items.push(orderItem)
//         else activeSubOrder.items = [orderItem]
//     }

//     // order.info.costInfo.total = orderTotalAmount(order)

//     if (orders) {
//         const index = orders.map(function (e: Order) { return e.info.references.id }).indexOf(order.info.references.id)
//         if (index >= 0) {
//             orders[index] = order
//         } else {
//             orders.push(order)
//         }
//     }

//     await localforage.setItem(tableName, orders)
//     return order
// }

// export const updateItem = async (externalReference: string, item: Item, oldItem: GroupedItem): Promise<Order | null> => {
//     //localforage.config(dbConfig)
//     const orders = await localforage.getItem<Order[]>(tableName)

//     const ordersFound = _.filter(orders, function (o) {
//         return o.info.references.externalReference === externalReference
//     })

//     const order = ordersFound.length > 0 ? ordersFound[0] : null

//     if (order) {
//         order.info.event = OrderEvent.Modified
//         const activeSubOrder = _.filter(order.subOrders, function (subOrder) {
//             return subOrder.isPrinted === false
//         })

//         activeSubOrder[0].event = OrderEvent.Modified
//         if (activeSubOrder.length < 1) return null

//         const items = _.filter(activeSubOrder[0].items, function (singleItem) {
//             return HashOrderItem(oldItem) === HashOrderItem(singleItem)
//         })

//         items.forEach((singleItem) => {
//             singleItem.modifiers = JSON.parse(JSON.stringify(item.modifiers))
//             singleItem.event = OrderEvent.Modified
//         })
//     }

//     await localforage.setItem(tableName, orders)
//     const uOrders = await localforage.getItem<Order[]>(tableName)

//     const filteredOrder = _.filter(uOrders, function (o) {
//         return o.info.references.externalReference === externalReference
//     })

//     return filteredOrder.length > 0 ? filteredOrder[0] : null
// }

// export const removeItem = async (externalReference: string, item: GroupedItem): Promise<Order | null> => {
//     //localforage.config(dbConfig)
//     const orders = await localforage.getItem<Order[]>(tableName)

//     const ordersFound = _.filter(orders, function (o) {
//         return o.info.references.externalReference === externalReference
//     })

//     const order = ordersFound.length > 0 ? ordersFound[0] : null

//     if (order) {
//         //gjejme suborder aktive
//         const activeSubOrder = _.filter(order.subOrders, function (subOrder) {
//             return subOrder.isPrinted === false
//         })

//         if (activeSubOrder.length < 1) return null

//         const orderedOrderItems = _.orderBy(activeSubOrder[0].items, 'sortIndex', 'desc')

//         activeSubOrder[0].items = JSON.parse(JSON.stringify(orderedOrderItems))

//         //heqim fillimisht ato qe nuk jane shtuar ne backend
//         const index = _.findIndex(activeSubOrder[0].items, function (itm) { return itm.event === OrderEvent.Add && HashOrderItem(itm) === HashOrderItem(item) })
//         if (index > -1 && activeSubOrder[0].items) {
//             _.pullAt(activeSubOrder[0].items, index)
//         } else {
//             // nese nuk gjendet asnje rast nga me siper, markojme me statusin 'remove' ato qe nuk jane markuar me kete status
//             const itemsFound = _.filter(activeSubOrder[0].items, function (itm) {
//                 return HashOrderItem(itm) === HashOrderItem(item) && itm.event !== OrderEvent.Remove
//             })

//             if (itemsFound.length > 0) {
//                 itemsFound[0].event = OrderEvent.Remove
//                 itemsFound[0].quantity = 0
//                 itemsFound[0].subTotal = 0
//             }
//         }

//         order.info.event = OrderEvent.Modified
//         // order.info.costInfo.total = orderTotalAmount(order)
//     }

//     await localforage.setItem(tableName, orders)
//     const uOrders = await localforage.getItem<Order[]>(tableName)

//     const filteredOrder = _.filter(uOrders, function (o) {
//         return o.info.references.externalReference === externalReference
//     })

//     return filteredOrder.length > 0 ? filteredOrder[0] : null
// }

// export const getOrderExternalReferenceFromTableId = async (tableId: number): Promise<string> => {

//     const orders = await getOrdersFromIndexedDb()
//     const tableOrder = _.filter(orders, function (order) {
//         return order.info.tableId === tableId && !order.info.status.isPaid
//     })

//     return tableOrder.length > 0 ? tableOrder[0].info.references.externalReference : guid()
// }

export const printSubOrder = async (orderData: Order) => {
    const activeSubOrder = _.filter(orderData.subOrders, function (subOrder) {
        return subOrder.isPrinted === false
    })

    if (activeSubOrder.length > 0) {
        activeSubOrder[0].isPrinted = true
        activeSubOrder[0].event = OrderEvent.Modified
    }

    console.log(JSON.stringify(orderData))

    await updateOrder(orderData)
}

// export const orderClear = async (orderData: Order) => {
//     if (!orderData) return
//     let orderIndexPosition = -1

//     if (!orderData) return orderData
//     if (orderData.subOrders.length === 0) return orderData

//     _.filter(orderData.subOrders, function (subOrder) {
//         if (subOrder.isPrinted === false) orderIndexPosition++
//         return (subOrder.isPrinted === false)
//     })

//     await updateOrder(orderData)

//     return orderData
// }

// export const processSingleOptionModifier = async (state, action) => {
//             if (action.payload.selectedModifiers.singleOptionModifier.length === 0) return state

//            const externalReference = action.payload.selectedModifiers.singleOptionModifier[0].externalReference

//            let orderData = await getOrderExternalReference(externalReference)
//             orderData = JSON.parse(JSON.stringify(orderData)) //clone object

//            const activeSubOrder = getActiveSubOrder(orderData)                        
//             const selectedModifiers = action.payload.selectedModifiers.singleOptionModifier

//              for (let i = 0; i < activeSubOrder.items.length; i++) {
//                const selectedModifierToAdd = _.filter(selectedModifiers, function (item) {
//                     return HashOrderItem(item.orderItem) === HashOrderItem(activeSubOrder.items[i])
//                 })

//                 if (selectedModifierToAdd.length > 0) {
//                 let modifierFound = _.filter(activeSubOrder.items[i].modifiers, function (modifier) {
//                     return  modifier.id === selectedModifierToAdd[0].menuModifier.id
//                 })

//                 //modifier does not exists in order item
//                 if (modifierFound.length === 0) {                    
//                     for (let j = 0; j < selectedModifierToAdd.length; j++) {

//                         modifierFound = _.filter(activeSubOrder.items[i].modifiers, function (modifier) {
//                             return  modifier.id === selectedModifierToAdd[j].menuModifier.id
//                         })

//                         let newModifier = null

//                         if (modifierFound.length === 0) {
//                             newModifier = selectedModifierToAdd[j].menuModifier
//                             newModifier.items = []
//                         } else newModifier = modifierFound[0]

//                         const newModifierInstance = JSON.parse(JSON.stringify(selectedModifierToAdd[j].modifierItem))
//                         newModifierInstance.reference = guid()

//                         if (selectedModifierToAdd[j].modifierItem.event === OrderEvent.Add) newModifier.items.push(newModifierInstance)
//                     else {
//                         const itemFound = _.filter(newModifier.items, function(singleItem) {
//                             return singleItem.reference === selectedModifierToAdd[0].modifierItem.reference
//                         })

//                         if (itemFound.length > 0) itemFound[0].event = OrderEvent.Remove
//                     }
//                     if (modifierFound.length === 0) activeSubOrder.items[i].modifiers.push(newModifier)
//                 }
//                 } else selectedModifierToAdd.forEach((data) => {
//                     if (data.modifierItem.event === OrderEvent.Add) modifierFound[0].items.push(data.modifierItem)
//                     else {  
//                         const itemsFound = _.filter(modifierFound[0].items, function(singleItem) {
//                             return singleItem.id === data.modifierItem.id && singleItem.event !== OrderEvent.Remove && data.event === OrderEvent.Remove
//                         })                        

//                         if (itemsFound.length > 0) itemsFound[0].event = OrderEvent.Remove
//                     }
//                 })
//                 }
//              }

//             await updateOrder(orderData)
//     return JSON.parse(JSON.stringify(orderData))
// }

// export const processMultiOptionsModifier = async (state, action, orderData) => {    
//     if (action.payload.selectedModifiers.multipleOptionModifier.length === 0) return state

//         //    const externalReference = action.payload.selectedModifiers.multipleOptionModifier[0].externalReference

//             // let orderData = await getOrderExternalReference(externalReference)
//             orderData = JSON.parse(JSON.stringify(orderData)) //clone object

//             const activeSubOrder = getActiveSubOrder(orderData)                        
//             const selectedModifiers = action.payload.selectedModifiers.multipleOptionModifier

//              for (let i = 0; i < activeSubOrder.items.length; i++) {
//                const selectedModifierToAdd = _.filter(selectedModifiers, function (item) {
//                     return HashOrderItem(item.orderItem) === HashOrderItem(activeSubOrder.items[i])
//                 })

//                 if (selectedModifierToAdd.length > 0) {
//                 let modifierFound = _.filter(activeSubOrder.items[i].modifiers, function (modifier) {
//                     return  modifier.id === selectedModifierToAdd[0].menuModifier.id
//                 })

//                 //modifier does not exists in order item
//                 if (modifierFound.length === 0) {                    
//                     for (let j = 0; j < selectedModifierToAdd.length; j++) {

//                         modifierFound = _.filter(activeSubOrder.items[i].modifiers, function (modifier) {
//                             return  modifier.id === selectedModifierToAdd[j].menuModifier.id
//                         })

//                         let newModifier = null

//                         if (modifierFound.length === 0) {
//                             newModifier = selectedModifierToAdd[j].menuModifier
//                             newModifier.items = []
//                         } else newModifier = modifierFound[0]

//                         const newModifierInstance = JSON.parse(JSON.stringify(selectedModifierToAdd[j].modifierItem))
//                         newModifierInstance.reference = guid()

//                         if (selectedModifierToAdd[j].modifierItem.event === OrderEvent.Add) newModifier.items.push(newModifierInstance)
//                     else {
//                         const itemFound = _.filter(newModifier.items, function(singleItem) {
//                             return singleItem.reference === selectedModifierToAdd[0].modifierItem.reference
//                         })

//                         if (itemFound.length > 0) itemFound[0].event = OrderEvent.Remove
//                     }
//                     if (modifierFound.length === 0) activeSubOrder.items[i].modifiers.push(newModifier)
//                 }
//                 } else selectedModifierToAdd.forEach((data) => {
//                     if (data.modifierItem.event === OrderEvent.Add) modifierFound[0].items.push(data.modifierItem)
//                     else {  
//                         const itemsFound = _.filter(modifierFound[0].items, function(singleItem) {
//                             return singleItem.id === data.modifierItem.id && singleItem.event !== OrderEvent.Remove && data.event === OrderEvent.Remove
//                         })                        

//                         if (itemsFound.length > 0) itemsFound[0].event = OrderEvent.Remove
//                     }
//                 })
//                 }
//              }

//             await updateOrder(orderData)
//         return JSON.parse(JSON.stringify(orderData))
// }

export const fiscalizeOrder = async (order: Order, employeeId: number) => {
    await processOrdersFromIndexedDb()

    const url = `/api/fiscalize/cash?employeeId=${employeeId}`
    const response = await axiosInstance.post(url, order)
    return response
}

// export const markOrderAsPaid = async (externalReference: string) => {

//     const order = await getOrderExternalReference(externalReference)
//     if (!order) return
//     // order.info.status.isPaid = true

//     for (let i = 0; i < order.subOrders.length; i++) {
//         order.subOrders[i].isPrinted = true
//     }

// const orders = await getOrdersFromIndexedDb()
// const index = orders.findIndex(obj => obj.info.references.externalReference === order.info.references.externalReference)

// orders[index] = order
// await localforage.setItem(tableName, orders)
// await updateOrderLocally(order)
// }


// export const toItemModifier = (modifierCat: ModifierCategory[]): Modifier[] => {

//     const modifiers: Modifier[] = modifierCat.map(mod => {
//         return {
//             id: mod.id,
//             type: "",
//             quantity: 0,
//             externalReference: mod.externalReference,
//             event: OrderEvent.Raw,
//             isSingleOption: mod.isSingleOption,
//             items: mod.items
//         }
//     })

//     return modifiers
// }