import dbConfig from "./db"
import axiosInstance from '../AxiosInstance'
import localforage from 'localforage'
import Settings from "src/entities/Settings/Settings"
import BusinessData from "src/entities/Business/BusinessData"

const tableName = "settings"
export const getSettings = async (): Promise<Settings[]> => {
  //localforage.config(dbConfig)
  let settings: Settings[] = []

  try {
    const response = await axiosInstance.get<Settings[]>(`/api/settings/section/non-system`)

    if (response.status === 200) {
      localforage.setItem(tableName, response.data)
      settings = [...response.data]
    }
  } catch (exception) {
    const data = await localforage.getItem<Settings[]>(tableName)
    if (data) settings = [...data]
  }
  return settings
}

export const getSettingsValue = async (key: String): Promise<String | undefined> => {

  //localforage.config(dbConfig)
  const settings = await localforage.getItem<Settings[]>(tableName)
  if (!settings) return undefined

  return settings.find(x => x.configKey === key)?.configValue
}

export const getSettingsFromIndexedDb = async (): Promise<Settings[] | null> => {

  //localforage.config(dbConfig)
  const settings = await localforage.getItem<Settings[]>(tableName)

  return settings
}

export const getBusinessData = async (): Promise<BusinessData> => {
  const settings = await localforage.getItem<Settings[]>(tableName)

  const businessName = settings?.find(x => x.configKey === 'CompanyName')?.configValue
  const address = settings?.find(x => x.configKey === "Address")?.configValue
  const nipt = settings?.find(x => x.configKey === "IssuerNUIS")?.configValue

  class Business implements BusinessData {
    name: String
    address: String
    nipt: String

    constructor(name: String, address: String, nipt: String) {
      this.name = name
      this.address = address
      this.nipt = nipt
    }
  }

  const businessData: BusinessData = new Business(businessName!, address!, nipt!)
  return businessData
}