import BusinessData from "src/entities/Business/BusinessData"

// actions.ts
export const setBusinessData = (data: any) => ({
    type: 'SET_BUSINESS_DATA' as const,
    payload: data
})

// reducers.ts
interface AppState {
    businessData: BusinessData | null
}

const initialState: AppState = {
    businessData: null
}

type AppAction = ReturnType<typeof setBusinessData>

const businessDataReducer = (state: AppState = initialState, action: AppAction): AppState => {

    switch (action.type) {
        case 'SET_BUSINESS_DATA':
            return {
                ...state,
                businessData: action.payload
            }
        default:
            return state
    }
}
export default businessDataReducer