
import { CheckCircle, X } from 'react-feather'

const ErrorToast = ({message}) => {
   return  (<>
        <div className='toastify-header'>
        <div className='title-wrapper'>
            {/* <Avatar size='sm' color='danger' icon={<X size={12} />} /> */}
            <h6 className='toast-title'>Error!</h6>
        </div>          
        </div>
        <div className='toastify-body'>
        <span role='img' aria-label='toast-text'>
            {message}
        </span>
        </div>
    </>
    )
   } 

export const InfoToast = ({message}) => {
    return  (<>
         <div className='toastify-header'>
         <div className='title-wrapper'>
             <Avatar size='sm' color='primary' icon={<CheckCircle size={12} />} />
             <h6 className='toast-title'>Information!</h6>
         </div>          
         </div>
         <div className='toastify-body'>
         <span role='img' aria-label='toast-text'>
             {message}
         </span>
         </div>
     </>
     )
    }
export default ErrorToast    