// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

// ** Toast & ThemeColors Context
import { ToastContainer } from 'react-toastify'

import {PinProvider} from "./auth/usePinAuthentication"

import './i18n/config'

import { createStore, applyMiddleware, compose  } from 'redux'
import allReducers from './reducers'
import { Provider } from 'react-redux'
import thunk from "redux-thunk" 

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
const store = createStore(allReducers, compose(applyMiddleware(thunk)))
  

ReactDOM.render(
  <Provider store={store}>   
    <Suspense>
      <PinProvider>
        <LazyApp />
        <ToastContainer newestOnTop />
        </PinProvider>
    </Suspense>
   </Provider>,
  document.getElementById('root')
)