import Customer from "src/entities/Customer/Customer"
import { DeliveryAddress } from "src/entities/Order/Info/Status/Delivery/DeliveryAddress"
import { updateCustomer } from "src/indexeddb/customer"

const defaultCustomer: Customer = {
    birthday: null,
    country: "",
    createdDate: new Date(),
    documentId: 0,
    documentType: "",
    email: "",
    event: "",
    gender: "",
    groupId: 0,
    groupName: "",
    id: "",
    idNumber: null,
    isBusiness: false,
    mobile: "",
    name: "",
    surname: "",
    town: null,
    addresses: []
}

const customerReducer = async (state: Customer = defaultCustomer, action) => {

    switch (action.type) {
        case "CUSTOMER_INIT":
            return action.payload

        case "CUSTOMER_SET_ADDRESS":
            {
                const customer = await state
                const address: DeliveryAddress = action.payload
                return { ...customer, adresses: [customer.addresses.filter(x => x.id !== address.id), address] }
            }

        case "CUSTOMER_ADD_ADDRESS":
            {
                const customer = await state
                const address: DeliveryAddress = action.payload
                const customerUpdated = { ...customer, addresses: [...customer.addresses.filter(x => x.id !== address.id), address] }

                await updateCustomer(customerUpdated)
                return customerUpdated
            }

        default:
            return state
    }
}

export default customerReducer