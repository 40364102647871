import dbConfig from "./db"
import axiosInstance from '../AxiosInstance'
import localforage from 'localforage'
import _ from "lodash"
import Customer from "../entities/Customer/Customer"
import { DocumentType } from "../entities/DocumentType/DocumentType"
import { Address } from "../entities/Customer/Address"
import { OrderEvent } from "../entities/Order/OrderEvent"

const tableName = 'customers'
export const getCustomers = async () => {
    // //localforage.config(dbConfig)
    let customers: Customer[] = []

    try {
        const response = await axiosInstance.get<Customer[]>(`/api/customer`)

        if (response.status === 200) {

            localforage.setItem(tableName, response.data)
            customers = [...response.data]
        }
    } catch (exception) {
        const data = await localforage.getItem<Customer[]>(tableName)
        if (data) customers = [...data]
    }
    return customers
}

export const getDocumentTypes = async () => {

    try {
        const response = await axiosInstance.get(`/api/customer/document-types`)

        if (response.status === 200) {
            // //localforage.config(dbConfig)
            localforage.setItem('documentTypes', response.data)
        }
    } catch (exception) { }

}

export const getDocumentsFromIndexedDb = async (): Promise<DocumentType[] | null> => {
    // //localforage.config(dbConfig)
    const documentTypes = await localforage.getItem<DocumentType[]>('documentTypes')

    return documentTypes
}

export const getCustomersFromIndexedDb = async (): Promise<Customer[]> => {
    // //localforage.config(dbConfig)
    const customers = await localforage.getItem<Customer[]>(tableName)

    return customers ? customers : []
}

export const getCustomerDetails = async (customerId: number) => {

    const url = `/api/customer/${customerId}`
    const response = await axiosInstance.get(url)

    if (response.status === 200) return response.data
    else return null

}

export const addCustomer = async (customerData: Customer) => {

    const response = await axiosInstance.post(`/api/customer`, customerData)

    await getCustomers()

    return response
}

export const getCustomerById = async (customerId: string): Promise<Customer | undefined> => {
    // //localforage.config(dbConfig)
    const customers = await localforage.getItem<Customer[]>(tableName)

    if (customers) return customers.find(x => x.id === customerId)
    else return undefined
}

export const getFilteredCustomer = async (param: string): Promise<Customer[]> => {
    const customers = await getCustomersFromIndexedDb()
    let isPhoneNumber = false

    param = param.toString().toLowerCase()
    if (/^(069|068|067|35569|35568|35567|\+35569|\+35568|\+35567)+/.test(param)) {
        if (param.startsWith("355")) param = `+${param}`
        if (param.startsWith("06")) param = `+355${param.slice(1)}`
        isPhoneNumber = true
    }

    const filteredCustomers = _.filter(customers, function (customer) {
        const customerName = customer.name ? customer.name.toString() : ''
        const customerSurname = customer.surname ? customer.surname.toString() : ''
        const customerMobile = customer.mobile ? customer.mobile.toString() : ''

        return (customerName.toLowerCase().indexOf(param) > -1 || customerSurname.toLowerCase().indexOf(param) > -1 || customerMobile.startsWith(param))
    })

    return filteredCustomers
}

export const formatPhoneNumber = (mobile: string): string => {
    if (/^(069|068|067|35569|35568|35567|\+35569|\+35568|\+35567)+/.test(mobile)) {
        if (mobile.startsWith("+355")) mobile = mobile.slice(4)
        if (mobile.startsWith("355")) mobile = mobile.slice(3)
        if (mobile.startsWith("06")) mobile = mobile.slice(1)

        return mobile
    }

    return ''
}

export const getCustomerAddress = async (customerId: number) => {
    const addresses = await axiosInstance.get<Address[]>(`/api/customeraddress/customer/${customerId}`)

    const formattedAddresses: object[] = []
    addresses.data.forEach(address => {
        const singleOption = { value: address.id, label: address.street }
        formattedAddresses.push(singleOption)
    })

    return formattedAddresses
}

export const getCustomerAddressDetails = async (id: number): Promise<Address[]> => {
    const response = await axiosInstance.get<Address[]>(`/api/customeraddress/${id}`)

    return response.data
}

export const addCustomerAddress = async (address: Address): Promise<Address[]> => {
    const addedAddress = await axiosInstance.post<Address[]>(`/api/customeraddress`, address)

    return addedAddress.data
}

export const getAddressesHtmlViewOff = async (customerId: number) => {
    const customers = await getCustomersFromIndexedDb()

    const customerFound = _.filter(customers, function (customer) {
        return customer.id === customerId
    })

    if (customerFound.length <= 0) return null

    const formattedAddresses: object[] = []
    customerFound[0].addresses?.forEach(address => {
        const singleOption = { value: address.id, label: address.street }
        formattedAddresses.push(singleOption)
    })

    return formattedAddresses
}

export const getAddressesOff = async (customerId: string): Promise<Address[] | null> => {
    const customers = await getCustomersFromIndexedDb()

    const customerFound = _.filter(customers, function (customer) {
        return customer.id === customerId
    })

    return customerFound.length <= 0 ? null : customerFound[0].addresses
}

export const getAddressDetailsOff = async (customerId: number, addressId: string) => {
    const customers = await getCustomersFromIndexedDb()

    const customerFound = _.filter(customers, function (customer) {
        return customer.id === customerId
    })

    if (customerFound.length <= 0) return null

    const addressFound = _.filter(customerFound[0].addresses, function (address) {
        return address.id === addressId
    })

    return addressFound.length > 0 ? addressFound[0] : null
}

export const customerAddAddress = async (customerId: string | null, address: Address) => {
    console.log(customerId, address)
    const customers = await getCustomersFromIndexedDb()

    const customerFound = _.filter(customers, function (customer) {
        return customer.id === customerId
    })

    customerFound[0].addresses?.push(address)
    customerFound[0].event = OrderEvent.Modified

    //localforage.config(dbConfig)
    localforage.setItem(tableName, customers)
}

export const customerUpdateAddress = async (customerId: string | null, address: Address) => {
    const customers = await getCustomersFromIndexedDb()

    const customerFound = _.filter(customers, function (customer) {
        return customer.id === customerId
    })

    const index = _.findIndex(customerFound[0].addresses, { id: address.id })
    customerFound[0].event = OrderEvent.Modified
    // Replace item at index using native splice
    customerFound[0].addresses?.splice(index, 1, address)


    //localforage.config(dbConfig)
    localforage.setItem(tableName, customers)
}

export const updateCustomer = async (customer: Customer) => {
    const customers = await getCustomersFromIndexedDb()

    //localforage.config(dbConfig)
    localforage.setItem(tableName, [...customers.filter(x => x.id !== customer.id), customer])
}

export const updateCustomers = async (customers: Customer[]) => {

    //localforage.config(dbConfig)
    localforage.setItem(tableName, customers)
}

export const processCustomerFromIndexedDb = async () => {
    const customers = await getCustomersFromIndexedDb()
    const url = `/api/customer/process`
    let hasError = false

    if (customers) {
        for (let i = 0; i < customers.length; i++) {
            if (customers[i].event !== "processed") {
                try {
                    const response = await axiosInstance.post(url, customers[i])
                    if (response.status === 200) customers[i] = response.data
                    else {
                        hasError = true
                        console.log(response)
                    }
                } catch (error) {
                    hasError = true
                }
            }
        }

        //localforage.config(dbConfig)
        localforage.setItem(tableName, customers)
    }
    return hasError
}