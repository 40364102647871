import dbConfig from "./db"
import axiosInstance from '../AxiosInstance'
import localforage from 'localforage'
import _ from "lodash"
import Waiter from "src/entities/Waiter/Waiter"

const tableName = 'waiter'
// export const getWaiter = async (id) => {
//     try {
//         const url = `/employee/waiter/${id}` 
//         const response = await axios.get(url)

//         if (response.status === 200) {
//             //localforage.config(dbConfig)
//             localforage.setItem(tableName, response.data)
//         }
//     } catch (error) {
//         if (process.env.REACT_APP_POS_LOG_EXCEPTIONS === "1") console.log(error.response)
//     }
// }

export const getWaiters = async () => {

    try {
        const response = await axiosInstance.get<Waiter[]>(`/api/employee/waiter`)

        if (response.status === 200) {
            //localforage.config(dbConfig)
            localforage.setItem(tableName, response.data)
        }
    } catch (exception) { }
}

export const getWaiterFromIndexedDb = async (): Promise<Waiter[] | null> => {
    //localforage.config(dbConfig)
    return await localforage.getItem<Waiter[]>(tableName)
}

// export const waiterValidatePin = async (pin) => {
//     //localforage.config(dbConfig)
//     const waiter =  await localforage.getItem(tableName)
//     return waiter.pin === pin
// }

export const waiterValidatePin = async (pin: String): Promise<boolean> => {
    //localforage.config(dbConfig)
    const waiters = await localforage.getItem<Waiter[]>(tableName)
    const waitersFound = waiters?.filter(x => x.pin === pin)

    if (!waitersFound) return false
    return waitersFound.length > 0
}

export const getWaiterByPin = async (pin: String): Promise<Waiter | undefined> => {
    //localforage.config(dbConfig)
    const waiters = await localforage.getItem<Waiter[]>(tableName)

    const waiterFound = waiters?.find(x => x.pin === pin)

    if (waiterFound) {
        localforage.setItem('auth-waiter', waiterFound)
        return waiterFound
    } else localforage.removeItem('auth-waiter')

    return waiterFound
}

export const getAuthenticatedWaiter = async (): Promise<Waiter | null> => {
    //localforage.config(dbConfig)
    const waiter = await localforage.getItem<Waiter>('auth-waiter')

    return waiter
}