const orderItemWeightReducer =  (state, action) => {
  
    switch (action.type) {
        case "SET_ORDER_ITEM_WEIGHT_DATA":
           return action.payload
        
        default:
            return {modalVisibility: false, quantity: 0, itemName: null, itemTypeId: null}
    }
}

export default orderItemWeightReducer