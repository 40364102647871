import { getWaiterByPin } from '../indexeddb/waiter'
import { useState, createContext, useContext, useEffect } from 'react'

export const PinContext = createContext()

export const PinProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    setCurrentUser(currentUser)
  }, [currentUser])

  const fetchCurrentUser = async (pin) => {
    const waiter = await getWaiterByPin(pin)
    setCurrentUser(waiter)
    return waiter
  }

  return (
    <PinContext.Provider value={{ currentUser, fetchCurrentUser }}>
      {children}
    </PinContext.Provider>
  )
}

export const useCurrentUser = () => useContext(PinContext)