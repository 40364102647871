import dbConfig from "./db"
import axiosInstance from '../AxiosInstance'
import localforage from 'localforage'
import { Area } from "../entities/Area/Area"
import _ from "lodash"

const tableName = 'area'
export const getAreas = async () => {
    // localforage.config(dbConfig)
    let areas: Area[] = []

    try {
        const response = await axiosInstance.get<Area[]>(`/api/area/details`)

        if (response.status === 200) {
            localforage.setItem(tableName, response.data)
            areas = response.data
        }
    } catch (error) {
        const data = await localforage.getItem<Area[]>(tableName)
        if (data) areas = data
    }
    return areas
}

// export const updateTableStatus = async (tableId: number | null, status: number) => {
//     if (!tableId) return
//     let areas: Area[] | null = await localforage.getItem<Area[]>(tableName)
//     if (!areas) return

//     const areaFound = areas.find(x => x.tables.find(y => y.id === tableId))

//     if (areaFound) {
//         const tableFound = areaFound.tables.find(x => x.id === tableId)
//         if (tableFound) {
//             tableFound.statusId = status
//             areaFound.tables = [...areaFound.tables.filter(x => x.id !== tableId), tableFound]

//             areas = [...areas.filter(x => x.id !== areaFound.id), areaFound]
//             localforage.setItem(tableName, areas)
//         }
//     }
// }

export const updateAreas = async (areas: Area[]) => {
    // localforage.config(dbConfig)
    localforage.setItem(tableName, areas)
}
