import _ from "lodash"
import { HashAllOrderItem } from "../utility/Helper"
import { OrderTypes } from "../entities/Order/OrderTypes"
import { Order } from "src/entities/Order/Order"
import { CategoryItem, Modifier } from "src/entities/Category/Category"
import { DeliveryAddress } from "src/entities/Order/Info/Status/Delivery/DeliveryAddress"
import { getActiveSubOrder, getOrdersFromIndexedDb, newOrder, newSubOrder, updateOrder } from "../indexeddb/orders"

const order: Promise<Order> = new Promise(() => { newOrder() })
const orderReducer = async (state: Promise<Order> = order, action) => {
    switch (action.type) {
        // case "GET_ORDER_DATA":
        //     {
        //         const externalReference = action.payload.orderExternalReference
        //         const orderData = await getOrderExternalReference(externalReference)

        //         return orderData
        //     }
        case "REFRESH_ORDER_DATA":
            return action.payload.orderData

        case "INITIALIZE_ORDER":
            {
                const initOrder = newOrder()
                initOrder.info.orderTypeInfo.id = action.payload.orderType

                initOrder.info.references.id = action.payload.orderExternalReference
                initOrder.info.employeeId = action.payload.userId
                initOrder.info.tableId = action.payload.tableId
                
                return JSON.parse(JSON.stringify(initOrder))
            }

        //new
        case "ORDER_ADD_ITEM":
            {
                const order: Order = await state
                const categoryItem: CategoryItem = action.payload

                const activeSubOrder = getActiveSubOrder(order)
                const itemFound = activeSubOrder.items.find(x => HashAllOrderItem(x) === HashAllOrderItem(categoryItem))

                if (itemFound) {
                    if (itemFound.quantity === null) itemFound.quantity = 0
                    itemFound.quantity += categoryItem.quantity!
                    activeSubOrder.items = [...activeSubOrder.items.filter(x => HashAllOrderItem(x) !== HashAllOrderItem(categoryItem)), itemFound]
                } else {
                    const maxOrderNo: CategoryItem | undefined = _.maxBy(activeSubOrder.items, 'sortPriority')
                    categoryItem.sortPriority = (maxOrderNo !== undefined ? maxOrderNo.sortPriority : 0) + 1
                    activeSubOrder.items = [...activeSubOrder.items, { ...categoryItem }]
                }

                const data: Order = { ...order, subOrders: [...(order.subOrders.filter(x => x.id !== activeSubOrder.id)), activeSubOrder] }

                // await updateTableStatus(order.info.tableId, 2)
                await updateOrder(data)
                return data
            }

        //new
        case "ORDER_REMOVE_ITEM":
            {
                const order: Order = await state
                const categoryItem: CategoryItem = action.payload

                const activeSubOrder = { ...getActiveSubOrder(order) }
                const itemFound = activeSubOrder.items.find(x => HashAllOrderItem(x) === HashAllOrderItem(categoryItem))

                if (itemFound) {
                    if (itemFound.quantity === null) itemFound.quantity = 0
                    itemFound.quantity -= categoryItem.quantity!

                    if (itemFound.quantity > 0) activeSubOrder.items = [...activeSubOrder.items.filter(x => HashAllOrderItem(x) !== HashAllOrderItem(categoryItem)), itemFound]
                    else activeSubOrder.items = [...activeSubOrder.items.filter(x => HashAllOrderItem(x) !== HashAllOrderItem(categoryItem))]
                }

                const data: Order = { ...order, subOrders: [...(order.subOrders.filter(x => x.id !== activeSubOrder.id)), activeSubOrder] }
                await updateOrder(data)

                return data
            }

        case "ORDER_ADD_MODIFIERS":
            {
                let order: Order | null = await state
                let { orderItem, modifiers, menuItem }: { orderItem: CategoryItem | undefined, modifiers: Modifier[], menuItem: CategoryItem | undefined } = action.payload
                const isInsert: boolean = action.payload.isInsert

                orderItem = orderItem ? JSON.parse(JSON.stringify(orderItem)) : orderItem
                menuItem = menuItem ? JSON.parse(JSON.stringify(menuItem)) : menuItem
                modifiers = JSON.parse(JSON.stringify(modifiers))

                if (!order) order = newOrder()
                const subOrder = getActiveSubOrder(order)

                if (!orderItem) {
                    orderItem = JSON.parse(JSON.stringify(menuItem))
                    const maxOrderNo: CategoryItem | undefined = _.maxBy(subOrder.items, 'sortPriority')
                    if (!orderItem) return

                    orderItem.sortPriority = (maxOrderNo !== undefined ? maxOrderNo.sortPriority : 0) + 1
                    orderItem.quantity = 1
                }
                if (isInsert) {
                    orderItem.modifiers = [...modifiers]
                    const orderItemFound = subOrder.items.find(x => HashAllOrderItem(x) === HashAllOrderItem(orderItem!))
                    if (orderItemFound) orderItem.quantity = orderItemFound.quantity! + (orderItem.quantity ? orderItem.quantity : 1)
                    subOrder.items = [...subOrder.items.filter(x => HashAllOrderItem(x) !== HashAllOrderItem(orderItem!)), orderItem]
                } else {
                    subOrder.items = [...subOrder.items.filter(x => HashAllOrderItem(x) !== HashAllOrderItem(orderItem!))]
                    orderItem.modifiers = [...modifiers]

                    const orderItemFound = subOrder.items.find(x => HashAllOrderItem(x) === HashAllOrderItem(orderItem!))
                    if (orderItemFound) {
                        subOrder.items = [...subOrder.items.filter(x => HashAllOrderItem(x) !== HashAllOrderItem(orderItem!))]
                        orderItem.quantity = orderItemFound.quantity! + (orderItem.quantity ? orderItem.quantity : 1)
                    }

                    subOrder.items = [...subOrder.items, orderItem]
                }

                order.subOrders = [...order.subOrders.filter(x => x.id !== subOrder.id), subOrder]

                // await updateTableStatus(order.info.tableId, 2)
                await updateOrder(order)
                return order
            }

        case "APPLY_SUBORDER_COMMENT":
            {
                const orderInfo: Order = await state

                if (!orderInfo) return
                const activeSubOrder = getActiveSubOrder(orderInfo)

                if (!activeSubOrder) return
                activeSubOrder.comment = action.payload

                const subOrders = orderInfo.subOrders.filter(x => x.id !== activeSubOrder.id)
                const order: Order = { info: orderInfo.info, subOrders: [...subOrders, activeSubOrder] }

                await updateOrder(order)

                return order
            }

        case "SET_ADDRESS":
            {
                let orderInfo: Order = await state
                const address: DeliveryAddress = action.payload

                orderInfo = JSON.parse(JSON.stringify(orderInfo))
                orderInfo.info.address = JSON.parse(JSON.stringify(address))

                await updateOrder(orderInfo)

                return orderInfo
            }

        case "MARK_ORDER_AS_PAID":
            {
                let orderState: Order = await state
                const orderPayload: Order = action.payload

                orderState = JSON.parse(JSON.stringify(orderState))

                orderState.info.references.orderNo = orderPayload.info.references.orderNo
                orderState.info.status.isPaid = orderPayload.info.status.isPaid

                await updateOrder(orderState)

                return orderState
            }

        case "CLEAR_ORDER":
            {
                const orderData: Order = await state
                const activeSubOrder = getActiveSubOrder(orderData)
                activeSubOrder.items = []

                return { ...orderData, subOrders: [...orderData.subOrders.filter(x => x.id !== activeSubOrder.id), activeSubOrder] }
            }

        case "ADD_SUBORDER":
            {
                const orderData: Order = await state
                orderData.subOrders = [...orderData.subOrders, newSubOrder()]

                await updateOrder(orderData)

                return { ...state, subOrders: [...orderData.subOrders] }
            }

        case "GET_TABLE_ORDER":
            {
                const tableId: number = action.payload
                const orders: Order[] = await getOrdersFromIndexedDb()

                const orderFound = orders.find(x => x.info.tableId === tableId)
                if (orderFound) return orderFound

                const newOrderData: Order = newOrder()
                newOrderData.info.orderTypeInfo.id = OrderTypes.dineIn
                newOrderData.info.tableId = tableId

                return newOrderData
            }

        case "CREATE_NEW_ORDER":
            {
                const orderType: number = action.payload.orderType
                const tableId: number | null = action.payload.tableId

                const newOrderData: Order = newOrder()
                newOrderData.info.orderTypeInfo.id = orderType
                newOrderData.info.tableId = tableId

                return newOrderData
            }

        case "APPLY_CUSTOMER":
            {
                let orderData: Order = await state
                const customerId: string = action.payload

                orderData = { ...orderData, info: { ...orderData.info, customerId } }

                await updateOrder(orderData)
                return orderData
            }

        case "REMOVE_CUSTOMER":
            {
                let orderData: Order = await state

                orderData = { ...orderData, info: { ...orderData.info, customerId: null } }

                await updateOrder(orderData)
                return orderData
            }

        case "UPDATE_ORDER_TYPE":
            {
                const orderType: number = action.payload
                const orderData: Order = await state
                orderData.info.orderTypeInfo.id = orderType

                if (orderType !== OrderTypes.dineIn) orderData.info.tableId = null

                await updateOrder(orderData)
                return orderData
            }

        case "APPLY_COURIER":
            {
                // let orderData = await getOrderExternalReference(action.payload.orderExternalReference)

                // if (!orderData) {
                //     orderData = newOrder()
                //     orderData.info.references.reference = action.payload.orderExternalReference
                //     orderData.info.orderTypeInfo.id = parseInt(action.payload.orderType)
                //     // orderData.info.orderTypeInfo.name = GetOrderTypeDescription(parseInt(action.payload.orderType))
                //     orderData.info.tableId = action.payload.tableId
                //     orderData.info.employeeId = action.payload.userId                  

                //     changeTableStatus(action.payload.tableId, 2) //mark table as busy
                // } else orderData = JSON.parse(JSON.stringify(orderData))

                // if (!orderData) return
                // orderData.info.status.deliveryInfo.courierId = action.payload.courierId
                // updateOrder(orderData)

                // return JSON.parse(JSON.stringify(orderData))

                return state
            }

        default:
            return state
    }
}

export default orderReducer