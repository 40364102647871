import orderReducer from "./order"
import orderItemWeightReducer from "./orderItemWeight"
import businessDataReducer from "./BusinessData"
import modifierReducer from "./modifiers"
import customerReducer from "./customer"
import customersReducer from "./customers"
import areaReducer from "./area"
import preloadReducer from "./preload"
import settingsReducer from "./settings"
import { combineReducers } from "redux"

const allReducers = combineReducers({
    orderReducer,
    orderItemWeightReducer,
    businessDataReducer,
    modifierReducer,
    customerReducer,
    customersReducer,
    areaReducer,
    preloadReducer,
    settingsReducer
})

export default allReducers